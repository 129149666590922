export const ROUTES = {
  ROOT: "/",
  EVENTS: {
    CODE_AND_BREWS: "/events/code-and-brews",
    CODE_AND_COFFEE: "/events/code-and-coffee",
    HACKREATION: "/events/hackreation",
    RESUME_WORKSHOP: "/events/resume-workshop",
    ROOT: "/events",
  },
  SPONSORS: "/sponsors",
  TEAM: "/team",
  CODE_OF_CONDUCT: "/code-of-conduct",
  PRIVACY: "/privacy",
  ABOUT: "/about",
};
